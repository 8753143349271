<template>
  <div class="home d-flex flex-column align-center mx-4">
    <img
      class="ma-8"
      alt="Logo"
      src="../assets/logo-circle.svg"
      :width="isMobile ? 128 : 256"
    />
    <div class="action-card-container">
      <template v-if="!userIsSignIn">
        <v-card class="action-card">
          <v-card-text>{{ $t("__home_sign_up_message") }}</v-card-text>
          <v-card-actions class="action-card__actions">
            <v-btn block color="primary" @click="toSignUp">
              {{ $t("__home_sign_up") }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="action-card">
          <v-card-text>{{ $t("__home_sign_in_message") }}</v-card-text>
          <v-card-actions class="action-card__actions">
            <v-btn block color="primary" :to="{ name: 'SignIn' }">
              {{ $t("__home_sign_in") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
      <v-card class="action-card">
        <v-card-text>{{ $t("__home_apply_store_message") }}</v-card-text>
        <v-card-actions class="action-card__actions">
          <v-btn block color="primary" @click="toApplyStore">
            {{ $t("__home_apply_store") }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <template v-if="userIsSignIn">
        <v-card class="action-card">
          <v-card-text>{{ $t("__home_to_stores_message") }}</v-card-text>
          <v-card-actions class="action-card__actions">
            <v-btn block color="primary" @click="toStore">
              {{ $t("__home_to_stores") }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="action-card">
          <v-card-text>{{ $t("__home_to_orders_message") }}</v-card-text>
          <v-card-actions class="action-card__actions">
            <v-btn block color="primary" :to="{ name: 'Orders' }">
              {{ $t("__home_to_orders") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const ApplyWebsiteURL = "https://apply.chia-market.com";

export default {
  name: "Home",
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      userIsSignIn: "user/isSignIn",
      isMobile: "isMobile",
      storeHistoryID: "store/storeHistoryID",
      storeIDs: "stores/ids"
    })
  },
  methods: {
    toSignUp() {
      window.location.replace(`${ApplyWebsiteURL}/sign-up`);
    },
    toApplyStore() {
      window.location.replace(`${ApplyWebsiteURL}/apply-store`);
    },
    toStore() {
      if (this.storeHistoryID) {
        this.loadUserStores();
      } else {
        this.loadUserStoresAndToStorePage();
      }
    },
    loadUserStores() {
      this.$store
        .dispatch("stores/getUserStores")
        .then(() => {
          this.checkStorePermission();
        })
        .catch(err => {
          console.log(err);
        });
    },
    loadUserStoresAndToStorePage() {
      this.$store.dispatch("stores/getUserStores").then(() => {
        this.$router.replace({
          name: "Store",
          params: { id: this.storeIDs[0] }
        });
      });
    },
    checkStorePermission() {
      let permissionAry = this.storeIDs.filter(item => {
        return item === this.storeHistoryID;
      });
      if (permissionAry.length) {
        this.$router.replace({
          name: "Store",
          params: { id: this.storeHistoryID }
        });
      } else {
        this.loadUserStoresAndToStorePage();
      }
    }
  }
};
</script>

<style lang="scss">
.action-card-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.action-card {
  position: relative;
  padding-bottom: 50px;
  height: 250px;
  width: 200px;
  margin: 16px;

  &__actions {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
</style>
